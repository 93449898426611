import React from "react";
import axios from "axios";
import md5 from "md5";
import {SERVER_URL} from "../config/configConstants";
import KPIEntry from "../components/KPIEntry/KPIEntry";
let indicators = require('../config/indicators.json');

class AdminView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            authorized: false,
            loaded: false,
            user: undefined,
            formData: undefined,
            authUsername: undefined,
            authPassword: undefined,
            responsibleData: undefined
        }
        this.handleChange = this.handleChange.bind(this);
    }
    async saveChanges(){
        let indicators = [];
        this.state.responsibleData.forEach((indicator) => {
            if(indicator.current_value){
                indicators.push(indicator)
            }
        })
        let result = await axios.post(SERVER_URL + "/uploadData", {
            username: this.state.authUsername,
            password: md5(this.state.authPassword),
            data: indicators
        })
        console.log(result);
    }
    async handleChange(e){
        await this.setState({
            [e.target.name] : e.target.value
        })
    }
    async handleKPIChange(e, index){
            let responsibleData = this.state.responsibleData;
            responsibleData[index].current_value = e.target.value
            console.log(responsibleData[index])
            console.log(e.target.value)
        await this.setState({
            responsibleData
        })
    }
    async logIn(){
        try{
        let result = await axios.post(SERVER_URL + "/logIn", {
            username: this.state.authUsername,
            password: md5(this.state.authPassword)
        })
        console.log(result);
        if(result.status === 200){
            await this.setState({
                authorized: true,
                user: result.data,
            })
            console.log(this.state.user)
        }
    }catch{
        alert("Incorrect login! Please try again.")
    }
    await this.determineResponsibleData();
    return true;
    }
    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    async determineResponsibleData(){
        let tempData = []
        indicators.forEach((indicator) => {
            this.state.user.roles.forEach((role) => {
                if(indicator.roles.includes(role)){
                    if(this.props.kpis[indicator.id] && !tempData.includes(indicator)){
                    indicator.current_value = this.props.kpis[indicator.id].current_value
                    tempData.push(indicator);
                    }
                }
            })
        })
        tempData = await tempData.filter(this.onlyUnique)
        console.log(tempData);
        await this.setState({
            loaded: true,
            responsibleData: tempData
        })
        await console.log(this.state.responsibleData)
    }
    render(){
        return(<>
        <div className="admin-wrapper">
            <h1>Admin Panel</h1>
            {!this.state.authorized ? <>
            <h1>Authenticate</h1>
            <p>Please use your provided credentials to authenticate. <br/>
            Don't have credentials? <a href="mailto:ahubbard@ybprep.org?cc=reece@occident.us, aboateng@ybprep.org&subject=YBDash Credential Request">Request them here</a> or email Anthony Hubbard.</p>
            <input type="text" name="authUsername" placeholder="Username" onChange={(e) => this.handleChange(e)}/>
            <input type="password" name="authPassword" placeholder="Password" onChange={(e) => this.handleChange(e)}/>
            <input type="submit" value="Log In" onClick={() => this.logIn()}/>
            </> : <>
            <h1>Data for {this.state.user.username}</h1>
            <h3>Active Role(s):</h3> <p>{this.state.user.roles.map((role, index) => {
                if(this.state.user.roles.length > 1){
                    return <>{role}, </>
                }else{
                    return role
                }
            })}</p>
            <p>Remember to press "Submit" when finished, or data changes will be lost.</p>
            <h4>Data you may change:</h4>
            {this.state.loaded ? <>
                <div className="kpi-container">
            <div className="kpi-object">
                <p>Description</p>
            </div>
            <div className="kpi-object">
                <p>Current Value</p>
            </div>
            </div>
            {this.state.responsibleData.map((indicator, index) => {
                return <KPIEntry customFunction={(e, index) => this.handleKPIChange(e, index)} {...indicator} index={index}/>
            })}
            <button className="saveButton" onClick={() => this.saveChanges()}>Save</button>
            </> : <p>Loading...</p>}
            </>}
            </div>
        </>)
    }
}

export default AdminView;