import React from "react";

class NavDetail extends React.Component{
    render(){
        return(<>
        <h1 className="bigTitle">A FOUNDATION OF TRANSPARENCY</h1>
        <p>We are an organization built on the qualities of transparency, honesty, and dedication to our students.
            <br/><br/>
            Our data takes the same approach. We track the metrics that let us know we are on the right track, and then make them publicly available.
            <br/><br/>
            This dashboard contains live data for the main metrics we track; if you're curious about how we calculated these metrics, click into any one to get a detailed breakdown.
        </p>
        <div className="detailCard">
            <h1>OUR STRATEGIC PRIORITIES</h1>
            <p>Develop infrastructure and practices that honor our core values while growing impact
            <br/><br/>
            Develop and deliver learning experiences that meet the needs of BYMOC
            </p>
        </div>
        <div className="detailCard">
            <h1>WE AIM TO BE</h1>
            <p>A model for building community and achieving excellence among boys and young men of color (BYMOC)
            <br/><br/>
            A leading voice for advocating for transformational, liberating education for BYMOC, and ensuring urban youth are valued, nurtured, and elevated as learners and leaders.
            <br/><br/>
            A teaching ground for excellence and leadership in education justice.
            </p>
        </div>
        <div className="detailCard">
            <h1>LEGEND</h1>
            <div className="thirds">
                <div className="third-object green">
                <h3>ON TARGET</h3>
                </div>
                <div className="third-object yellow">
                <h3>NEARLY ON TARGET</h3>
                </div>
                <div className="third-object red">
                <h3>NOT ON TARGET</h3>
                </div>
            </div>
        </div>
        </>)
    }
}

export default NavDetail;