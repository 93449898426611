import React from "react"

class IndicatorDetail extends React.Component{
    constructor(props){
        super(props)
        this.runLoad = this.runLoad.bind(this);
        this.state = {
            loaded: false
        }
    }
    runLoad(){
        if(!this.state.loaded){
            this.setState({loaded: true})
        }
    }
    componentDidMount(){
        this.runLoad();
    }
    componentDidUpdate(){
        this.runLoad();
    }
    render(){
        return this.state.loaded ? <>
        <div className="detail-row">
        <div className={"color-indicator " + this.props.colorCode}>
            &nbsp;
        </div>
        <div className="detail-content">
        <div className="detail-object">
            <p>{this.props.current_value != "grey" ? this.props.current_value : "N/A"}</p>
        </div>
        <div className="detail-object">
            <p>{this.props.description}</p>
        </div>
        <div className="detail-object">
            <p>{this.props.goal}</p>
        </div>
        </div>
        </div>
        </> : <><p>Loading</p></>;
    }
}

export default IndicatorDetail;