import React from "react";

class KPIEntry extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            inputValues: null
        }
    }
    componentDidMount(){
        this.determineInputValues();
    }
    componentDidUpdate(prevProps){
        if(prevProps.current_value !== this.props.current_value){
            this.determineInputValues();
        }
    }
    async determineInputValues(){
        let values = ["green", "yellow", "red"]
        let inputValues = []
        switch(this.props.type){
            case "mc":
                <h1>multiple choice</h1>
                values.map((val) => {
                    inputValues.push(<>
                        <input checked={this.props.current_value == this.props[val]} name={this.props.id} active={this.props.current_value == this.props[val]} value={this.props[val]} type="radio" onClick={(e) => this.props.customFunction(e, this.props.index)}/>
                        <label for={this.props.id}>{this.props[val]}</label>
                        </>)
                })
                break;
            case "number":
                inputValues.push(<>
                <input type="number" name={this.props.id} value={this.props.current_value} onChange={(e) => this.props.customFunction(e, this.props.index)}/>
                </>)
                break;
            case "percentage":
                inputValues.push(<>
                <input type="number" min="0" max="100" name={this.props.id} value={this.props.current_value} onChange={(e) => this.props.customFunction(e, this.props.index)}/>
                <label for={this.props.id}> percent of goal met</label>
                </>);
                break;
            default:
                inputValues.push(<p>No choice</p>)
        }
        await inputValues.push(<>
            <input checked={this.props.current_value == "grey"} name={this.props.id} active={this.props.current_value == "grey"} value="grey" type="radio" onClick={(e) => this.props.customFunction(e, this.props.index)}/>
            <label for={this.props.id}> Grey out indicator</label>
            </>)
        await this.setState({inputValues})
        return true;
        }
    render(){
        return(<>
            <hr/>
            <div className="kpi-container">
            <div className="kpi-object">
                <p>{this.props.description}</p>
            </div>
            <div className="kpi-object">
                <p>{this.props.current_value ? this.props.current_value : "No value set"}</p>
            </div>
            </div>
            <div className="kpi-container">
                {this.state.inputValues}
            </div>
        </>)
    }
}

export default KPIEntry;