import React from "react";
import {Link} from 'react-router-dom';

class EvalCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sizeClass: undefined
        }
        this.paragraphElement = React.createRef();
        this.divElement = React.createRef();
    }
    componentDidMount(){
        console.log(this.paragraphElement.current.clientHeight)
        console.log(this.divElement.current.clientHeight)
        if((this.paragraphElement.current.clientHeight + 15) > this.divElement.current.clientHeight){
            this.setState({sizeClass: "xl"});
        }
    }
    componentDidUpdate(){
    }
    render(){
        return <>
        <Link to={"/shortcode/" + this.props.shortcode}>
        <div className={"yb-eval-card " + this.state.sizeClass + " " + this.props.colorCode} ref={this.divElement}>
            <p ref={this.paragraphElement}>{this.props.desc}</p>
        </div>
        </Link>
        </>
    }
}

export default EvalCard;