import React from "react";
import EvalCard from '../components/EvalCard/EvalCard';

class Dashboard extends React.Component{
    render(){
        return(
            this.props.loaded ? 
                <>
                <div className="padded">
                <h1>Operations</h1>
                <p className="subtext">Advance Operational Excellence and Growth</p>
                <div className="yb-eval-row">
                  {
                    this.props.o.map((item) => {
                      return <EvalCard {...item}/>
                    })
                  }
                </div>
                <h1>Finances</h1>
                <p className="subtext">Ensure Financial Sustainability</p>
                <div className="yb-eval-row">
                  {
                    this.props.s.map((item) => {
                      return <EvalCard {...item}/>
                    })
                  }
                </div>
                <h1>Impact</h1>
                <p className="subtext">Expand Impact and Reach</p>
                <div className="yb-eval-row">
                  {
                    this.props.i.map((item) => {
                      return <EvalCard {...item}/>
                    })
                  }
                </div>
                <h1>Engagement</h1>
                <p className="subtext">Increase Engagemet and Understanding of Work/Mission</p>
                <div className="yb-eval-row">
                  {
                    this.props.e.map((item) => {
                      return <EvalCard {...item}/>
                    })
                  }
                </div></div>
                </> : "loading"
        )
    }
}

export default Dashboard