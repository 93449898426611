import axios from 'axios';
import React from 'react';
import './App.css';
import Dashboard from './Dashboard/Dashboard';
import ShortcodeView from './ShortcodeView/ShortcodeView';
import {SERVER_URL, RED_DIVIDER, WARN_DIVIDER} from './config/configConstants'
import {Switch, Route, Link} from 'react-router-dom'
import AdminView from './AdminView/AdminView';
import NavDetail from './components/NavDetail/NavDetail';
import LogoImage from './assets/img/YB_Logo.png';
let indicators = require('./config/indicators.json');
let shortcodes = require('./config/shortcodes.json');
const defaultCount = {
  green: 0,
  yellow: 0,
  red: 0,
  grey: 0
}

class App extends React.Component{
  constructor(props){
    super(props);
    var defaultIndicatorColors = {};
    var defaultIndicators = {};
    console.log(indicators);
    indicators.forEach((i) => {
      defaultIndicators = {
        ...defaultIndicators,
        [i.shortcode] : []
      }
    })
    indicators.forEach((i) => {
      defaultIndicatorColors = {
        ...defaultIndicatorColors,
        [i.shortcode] : {
          green: 0,
          yellow: 0,
          red: 0,
          grey: 0
        }
      }
    })
    this.determineIndicatorColor = this.determineIndicatorColor.bind(this);
    this.assignIndicatorCategories = this.assignIndicatorCategories.bind(this);
    this.assignShortcodeCategories = this.assignShortcodeCategories.bind(this);
    this.calculateShortcodeColor = this.calculateShortcodeColor.bind(this);
    this.consolidateIndicators = this.consolidateIndicators.bind(this);
    this.runLoad = this.runLoad.bind(this);
    this.state = {
      loaded: false,
      shortcode: [],
      o: [],
      s: [],
      i: [],
      e: [],
      oIndicators: [],
      sIndicators: [],
      iIndicators: [],
      eIndicators: [],
      consolidatedIndicators: defaultIndicators,
      indicatorColors: defaultIndicatorColors
    }
  }
  componentDidMount(){
    this.runLoad();
  }
  async runLoad(){
    await this.determineIndicatorColor();
    await this.assignIndicatorCategories();
    await this.calculateShortcodeColor();
    await this.assignShortcodeCategories();
    await this.consolidateIndicators();
    console.log(this.state);
    this.setState({loaded: true});
  }
  async consolidateIndicators(){
    // o, s, i, e 
    let conIndicators = this.state.consolidatedIndicators;
    await this.state.oIndicators.forEach((i) => {
      conIndicators = {
        ...conIndicators,
        [i.shortcode] : [...conIndicators[i.shortcode], {
          key: i._key,
          active: i.active,
          goal: i.goal,
          colorCode: i.colorCode,
          current_value: i.current_value,
          description: i.description,
          due_date: i.due_date,
          frequency: i.frequency,
          roles: i.roles,
          type: i.type
      }]
      }
    })
    await this.state.sIndicators.forEach((i) => {
      conIndicators = {
        ...conIndicators,
        [i.shortcode] : [...conIndicators[i.shortcode], {
          key: i._key,
          active: i.active,
          goal: i.goal,
          colorCode: i.colorCode,
          current_value: i.current_value,
          description: i.description,
          due_date: i.due_date,
          frequency: i.frequency,
          roles: i.roles,
          type: i.type
      }]
      }
    })
    await this.state.iIndicators.forEach((i) => {
      conIndicators = {
        ...conIndicators,
        [i.shortcode] : [...conIndicators[i.shortcode], {
          key: i._key,
          active: i.active,
          goal: i.goal,
          colorCode: i.colorCode,
          current_value: i.current_value,
          description: i.description,
          due_date: i.due_date,
          frequency: i.frequency,
          roles: i.roles,
          type: i.type
      }]
      }
    })
    await this.state.eIndicators.forEach((i) => {
      conIndicators = {
        ...conIndicators,
        [i.shortcode] : [...conIndicators[i.shortcode], {
            key: i._key,
            active: i.active,
            goal: i.goal,
            colorCode: i.colorCode,
            current_value: i.current_value,
            description: i.description,
            due_date: i.due_date,
            frequency: i.frequency,
            roles: i.roles,
            type: i.type
        }]
      }
    })
    await this.setState({
      consolidatedIndicators: conIndicators
    })
    return true;
  }
  async uploadData(){
    let response = await axios.post(SERVER_URL + "/uploadData", {indicators: indicators});
  }
  async determineIndicatorColor(){
    let kpis = await axios.post(SERVER_URL + "/getData");
    //call to database, check current status of data
    await this.setState({kpis: kpis.data});
    let tempIndicators = this.state.kpis
    let tempCount = this.state.indicatorColors;
    indicators.map((indicator) => {
      let shortCharacter = indicator.shortcode;
      if(indicator.active && indicator.type){
      if(this.state.kpis[indicator.id].current_value == "grey"){
        tempIndicators = {
          ...tempIndicators,
          [indicator.id]: {
            ...tempIndicators[indicator.id],
            colorCode: "grey"
            }
          }
        tempCount = {
          ...tempCount,
          [shortCharacter]: {
            ...tempCount[shortCharacter],
            grey: tempCount[shortCharacter].grey + 1
          }
        }
      }else if(indicator.type === "mc"){
        if(this.state.kpis[indicator.id].current_value == indicator.green){
        tempIndicators = {
          ...tempIndicators,
          [indicator.id]: {
            ...tempIndicators[indicator.id],
            colorCode: "green"
            }
          }
        tempCount = {
          ...tempCount,
          [shortCharacter]: {
            ...tempCount[shortCharacter],
            green: tempCount[shortCharacter].green + 1
          }
        }
        }else if(this.state.kpis[indicator.id].current_value == indicator.yellow){
        tempIndicators = {
          ...tempIndicators,
          [indicator.id]: {
            ...tempIndicators[indicator.id],
            colorCode: "yellow"
            }
          }
        tempCount = {
          ...tempCount,
          [shortCharacter]: {
            ...tempCount[shortCharacter],
            yellow: tempCount[shortCharacter].yellow + 1
          }
        }        
      }else if(this.state.kpis[indicator.id].current_value == "grey"){
        tempIndicators = {
          ...tempIndicators,
          [indicator.id]: {
            ...tempIndicators[indicator.id],
            colorCode: "grey"
            }
          }
        tempCount = {
          ...tempCount,
          [shortCharacter]: {
            ...tempCount[shortCharacter],
            grey: tempCount[shortCharacter].grey + 1
          }
        }
      }else{
        tempIndicators = {
          ...tempIndicators,
          [indicator.id]: {
            ...tempIndicators[indicator.id],
            colorCode: "red"
            }
          }
        tempCount = {
          ...tempCount,
          [shortCharacter]: {
            ...tempCount[shortCharacter],
            red: tempCount[shortCharacter].red + 1
          }
        }        
      }
      }else{
        if(this.state.kpis[indicator.id].current_value >= indicator.green){
          tempIndicators = {
            ...tempIndicators,
            [indicator.id]: {
              ...tempIndicators[indicator.id],
              colorCode: "green"
              }
            }
          tempCount = {
            ...tempCount,
            [shortCharacter]: {
              ...tempCount[shortCharacter],
              green: tempCount[shortCharacter].green + 1
            }
          }
        }else if(this.state.kpis[indicator.id].current_value >= indicator.yellow){
          tempIndicators = {
            ...tempIndicators,
            [indicator.id]: {
              ...tempIndicators[indicator.id],
              colorCode: "yellow"
              }
            }
            tempCount = {
              ...tempCount,
              [shortCharacter]: {
                ...tempCount[shortCharacter],
                yellow: tempCount[shortCharacter].yellow + 1
              }
            }        
          }else{
          tempIndicators = {
            ...tempIndicators,
            [indicator.id]: {
              ...tempIndicators[indicator.id],
              colorCode: "red"
              }
            }
          tempCount = {
            ...tempCount,
            [shortCharacter]: {
              ...tempCount[shortCharacter],
              red: tempCount[shortCharacter].red + 1
            }
          }
          }        
        }
      }
    })
    await this.setState({
      kpis: tempIndicators,
      indicatorColors : tempCount,
    })
    return true;
  }
  async assignShortcodeCategories(){
    let o = [];
    let s = [];
    let i = [];
    let e = [];
      this.state.shortcode.map((sc) => {
        switch(sc.shortcode.charAt(0).toLowerCase()){
          case "o":
            o.push(sc);
            return true;
          case "s":
            s.push(sc);
            return true;
          case "i":
            i.push(sc);
            return true;
          case "e":
            e.push(sc);
            return true;
          default:
            console.log("Error! Shortcode not categorized. Shortcode is " + sc.shortcode)
            return false;
        }
      })
      await this.setState({
        o,
        s,
        i,
        e
      })
      return true;
  }
  async calculateShortcodeColor(){
    let shortcodeCollection = [];
    shortcodes.forEach((sc, index) => {
        let colors = this.state.indicatorColors[sc.shortcode];
        let totalSum = colors.green + colors.yellow + colors.red;
        let failThreshhold = totalSum/RED_DIVIDER
        let warnThreshhold = totalSum/WARN_DIVIDER
        if(colors.red > failThreshhold){
          shortcodes[index].colorCode = "red"
        }else if(totalSum == 0){
          shortcodes[index].colorCode = "grey"
        }else if(colors.red > 1){
          shortcodes[index].colorCode = "yellow"
        }else if(colors.yellow > warnThreshhold){
          shortcodes[index].colorCode = "yellow"
        }else if(colors.green === totalSum){
          shortcodes[index].colorCode = "green"
        }else{
          shortcodes[index].colorCode = "grey"
        }
        shortcodeCollection.push(shortcodes[index]);
      })
      await this.setState({
        shortcode: shortcodeCollection
      })
      return true;
  }
  async assignIndicatorCategories(){
    let oIndicators = [];
    let sIndicators = [];
    let iIndicators = [];
    let eIndicators = [];
    Object.keys(this.state.kpis).map((kpi) => {
      let shortcode = this.state.kpis[kpi].shortcode;
      switch(shortcode.charAt(0).toLowerCase()){
        case 'o':
          oIndicators.push(this.state.kpis[kpi]);
          return true;
        case 's':
          sIndicators.push(this.state.kpis[kpi]);
          return true;
        case 'i':
          iIndicators.push(this.state.kpis[kpi]);
          return true;
        case 'e':
          eIndicators.push(this.state.kpis[kpi]);
          return true;
        default: 
          console.log("ERROR! KPI shortcode does not match any value. KPI ID is " + this.state.kpis[kpi]._key);
          return true;
      }
    });
    await this.setState({
      oIndicators, 
      sIndicators,
      iIndicators,
      eIndicators
    });
    return true;
  }

  render(){
  return (
    <div className="frame">
      <div className="topbar">
      <div className="logoContainer">
        <Link to="/"><img src={LogoImage} className="logoImage"/></Link>
      </div>
      </div>
      <div className="primaryFrame">
      <div className="navbar">
        <div className="navbar-content">
        <NavDetail/>
        </div>
      </div>
      <div className="content-area">
      { this.state.loaded ?
      <Switch>
        <Route path="/" exact>
          <Dashboard {...this.state}/>
        </Route>
        <Route path="/shortcode/:shortcode">
          <ShortcodeView {...this.state}/>
        </Route>
        <Route path="/admin">
          <AdminView kpis={this.state.kpis}/>
        </Route>
      </Switch> : "loading"
    }
    </div>
    </div>
    </div>
  );
  }
}

export default App;
