import React from "react";
import {withRouter} from "react-router-dom";
import IndicatorDetail from "../components/IndicatorDetail/IndicatorDetail";

class ShortcodeView extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            colorCode: null,
            description: null
        }
        this.checkLoad = this.checkLoad.bind(this);
    }
    checkLoad(){
        console.log(this.props.loaded)
        if(!this.state.loaded && this.props.loaded){
        this.props.shortcode.map((sc) => {
            if(sc.shortcode == this.props.match.params.shortcode){
                this.setState({
                    shortcode: sc.shortcode,
                    colorCode: sc.colorCode,
                    description: sc.desc,
                    loaded: true,
                })
            }
        })
        }
        console.log(this.props.consolidatedIndicators[this.state.shortcode]);
    }
    componentDidMount(){
        this.checkLoad();
    }
    componentDidUpdate(){
        this.checkLoad();
    }
    render(){
        return(
        this.state.loaded ?
        <>
        <div className={"fullscreen " + this.state.colorCode}>
            <h1>{this.state.description}</h1>
        </div>
        <div className="detail-row">
        <div className={"color-indicator"}>
            &nbsp;
        </div>
        <div className="detail-content">
        <div className="detail-object">
            <p>Current Value</p>
        </div>
        <div className="detail-object">
            <p>Description</p>
        </div>
        <div className="detail-object">
            <p>Goal</p>
        </div>
        </div>
        </div>
        {this.props.consolidatedIndicators[this.state.shortcode].map((indicator) => {
            return <IndicatorDetail {...indicator}/>
        })}
        </>
        : <p>loading</p>
        )
    }
}

export default withRouter(ShortcodeView);